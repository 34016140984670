//Generated by Java Application on Thu Sep 12 11:16:16 EDT 2024

import localeAntd from 'antd/es/locale/pt_BR'

const messages = {
'casino.cashier.alert1.description': 'Você precisa preencher suas informações pessoais para depositar ou sacar. Clique neste aviso ou acesse "Editar Perfil".',
'casino.cashier.alert1.message': 'Importante!',
'casino.cashier.alert2.description': 'Verifique seu número de telefone e adicione uma foto de identificação para sacar. Clique neste aviso ou acesse "Editar Perfil".',
'casino.cashier.alert3.description': 'Seus documentos KYC foram rejeitados. Atualize as fotos em seu perfil. Clique neste aviso ou acesse "Editar perfil".',
'casino.cashier.currentBalance': 'Saldo Atual',
'casino.general.date.from': 'De',
'casino.general.date.to': 'para',
'casino.iframegame.title': 'CASSINO',
'casino.modal.popup.close': 'Fechar',
'casino.rg.activitycheck': 'Verificação de atividade',
'casino.rg.activitycheckmsg': 'Opção para definir um lembrete para notificações com a descrição do tempo gasto jogando.',
'casino.rg.depositlimit': 'Limite de Depósito',
'casino.rg.depositlimitmsg': 'Defina um limite de qual valor você pode depositar em um determinado período de tempo.',
'casino.rg.losslimit': 'Limite de Perda',
'casino.rg.losslimitmsg': 'Defina um limite para o valor máximo de dinheiro que você pode perder em um determinado período.',
'casino.rg.selfexclusion': 'Autoexclusão',
'casino.rg.selfexclusionmsg': 'Faça uma pausa por tempo indeterminado solicitando a autoexclusão (Mínimo de 6 meses).',
'casino.rg.setlimit': 'Definir Limite',
'casino.rg.takebreak': 'Faça uma pausa.',
'casino.rg.takebreakmsg': 'Se você deseja fazer uma pausa rápida nos jogos, pode escolher períodos de 1 dia, 7 dias ou 30 dias.',
'casino.rg.title': 'Jogo Responsável',
'newCasino.admin.partnerDeal.title': 'Contrato do Parceiro',
'newCasino.admin.streamerDeal.amount': 'Quantia',
'newCasino.admin.streamerDeal.cancel': 'Cancelar',
'newCasino.admin.streamerDeal.giveaway': 'Valor do sorteio',
'newCasino.admin.streamerDeal.perweek': 'Transmissão por semana',
'newCasino.admin.streamerDeal.save': 'Salvar',
'newCasino.admin.streamerDeal.subtitle': 'Ajustar os parâmetros de negociação do streamer',
'newCasino.admin.streamerDeal.title': 'Contrato do Streamer',
'newCasino.admin.streamerDeal.xtime': 'Apostar X vezes',
'newcasino.admin.freespin.add.success': '{AMOUNT} rodadas grátis foram adicionadas à carteira do jogador.',
'newcasino.admin.freespin.remove.success': '{AMOUNT} rodadas grátis foram removidas da carteira do jogador.',
'newcasino.admin.kyc.approved': 'O pedido KYC foi aprovado',
'newcasino.admin.kyc.reject': 'O pedido KYC foi recusado',
'newcasino.admin.money.add.success': 'A quantia de {AMOUNT} foi adicionada à conta do jogador.',
'newcasino.admin.money.remove.success': 'A quantia de {AMOUNT} foi removido da conta do jogador.',
'newcasino.admin.pngpoints.add.success': '{AMOUNT} pontos foram adicionados ao jogador.',
'newcasino.admin.pngpoints.remove.success': '{AMOUNT} pontos foram removidos do jogador.',
'newcasino.admin.prom.depositbonus.tx.col1': 'E-mail',
'newcasino.admin.prom.depositbonus.tx.col2': 'Moeda',
'newcasino.admin.prom.depositbonus.tx.col3': 'Prêmio',
'newcasino.admin.prom.depositbonus.winners': 'Vencedores',
'newcasino.admin.prom.wager.tx.col1': 'E-mail',
'newcasino.admin.prom.wager.tx.col2': 'Moeda',
'newcasino.admin.prom.wager.tx.col3': 'Saldo',
'newcasino.admin.prom.wager.tx.col4': 'Meta de Aposta',
'newcasino.admin.prom.wager.tx.col5': 'Prêmio',
'newcasino.admin.spinInfo.col1': 'Nome do jogo',
'newcasino.admin.spinInfo.col2': 'Nbr Rodadas',
'newcasino.admin.user.address.address.error': 'O endereço é muito curto',
'newcasino.admin.user.address.address.hint': 'Digite o endereço aqui',
'newcasino.admin.user.address.address.label': 'Endereço',
'newcasino.admin.user.address.city.error': 'O nome da cidade está muito curto',
'newcasino.admin.user.address.city.hint': 'Digite o nome da cidade aqui',
'newcasino.admin.user.address.city.label': 'Cidade',
'newcasino.admin.user.address.country.hint': 'Selecione o país...',
'newcasino.admin.user.address.country.label': 'País',
'newcasino.admin.user.address.currency.label': 'Moeda',
'newcasino.admin.user.address.dob.label': 'Data de Nascimento',
'newcasino.admin.user.address.email.label': 'E-mail',
'newcasino.admin.user.address.id.label': 'Id',
'newcasino.admin.user.address.phone.label': 'Número de Telefone',
'newcasino.admin.user.address.regdate.label': 'Data de Cadastro',
'newcasino.admin.user.address.sponsorId.label': 'ID do Patrocinador',
'newcasino.admin.user.address.state.error': 'O nome do estado é muito curto',
'newcasino.admin.user.address.state.hint': 'Digite o nome do estado aqui',
'newcasino.admin.user.address.state.label': 'Estado',
'newcasino.admin.user.address.zip.error': 'O CEP é muito curto',
'newcasino.admin.user.address.zip.hint': 'CEP',
'newcasino.admin.user.address.zip.label': 'CEP',
'newcasino.admin.user.button': 'Atualizar',
'newcasino.admin.user.note.hint': 'Adicionar nota ao arquivo do usuário',
'newcasino.admin.user.note.label': 'Observação',
'newcasino.admin.user.pngpoint.hist.col1': 'Data',
'newcasino.admin.user.pngpoint.hist.col2': 'Type',
'newcasino.admin.user.pngpoint.hist.col3': 'Detalhe',
'newcasino.admin.user.pngpoint.hist.col4': 'Preço',
'newcasino.admin.user.pngpoints.add.button': '+/- Pontos',
'newcasino.admin.user.pngpoints.available': 'Pontos de fidelidade disponíveis',
'newcasino.admin.user.pngpoints.daily.list.title': 'Presentes diários',
'newcasino.admin.user.pngpoints.level': 'Nível de fidelidade',
'newcasino.admin.user.pngpoints.purchase.list.title': 'Detalhes da compra',
'newcasino.admin.user.pngpoints.subtitle': 'Adicionar/remover pontos de fidelidade',
'newcasino.admin.user.pngpoints.title': 'Loyalty Points',
'newcasino.admin.user.pngpoints.used': 'Pontos de fidelidade usados',
'newcasino.admin.user.popup.cash.amount.hint': 'Quantia',
'newcasino.admin.user.popup.cash.amount.label': 'Quantia',
'newcasino.admin.user.popup.cash.button.add': 'Adicionar/Remover',
'newcasino.admin.user.popup.cash.button.cancel': 'Cancelar',
'newcasino.admin.user.popup.cash.subtitle': 'Adicionar/Remover dinheiro real à carteira do jogador',
'newcasino.admin.user.popup.cash.title': 'Dinheiro real',
'newcasino.admin.user.popup.freespin.button.add': 'Adicionar/Remover',
'newcasino.admin.user.popup.freespin.button.cancel': 'Cancelar',
'newcasino.admin.user.popup.freespin.info.subtitle': 'Informações sobre Freespins de fornecedores de jogos',
'newcasino.admin.user.popup.freespin.quantity.hint': 'Quantidade',
'newcasino.admin.user.popup.freespin.quantity.label': 'Quantidade',
'newcasino.admin.user.popup.freespin.subtitle': 'Adicionar/Remover rodadas grátis à/da carteira',
'newcasino.admin.user.popup.freespin.title': 'Rodadas grátis',
'newcasino.admin.user.popup.switchphone.button.add': 'Faça isso',
'newcasino.admin.user.popup.switchphone.button.cancel': 'Cancelar',
'newcasino.admin.user.popup.switchphone.combo.label': 'Ação',
'newcasino.admin.user.popup.switchphone.subtitle': 'Selecione a ação a ser executada',
'newcasino.admin.user.popup.switchphone.success': 'A ação foi concluída',
'newcasino.admin.user.popup.switchphone.title': 'Alterar status do telefone',
'newcasino.admin.user.popup.switchstatus.button.add': 'Atualizar',
'newcasino.admin.user.popup.switchstatus.button.cancel': 'Cancelar',
'newcasino.admin.user.popup.switchstatus.combo.label': 'Tipo de usuário',
'newcasino.admin.user.popup.switchstatus.subtitle': 'Selecione o novo tipo de usuário',
'newcasino.admin.user.popup.switchstatus.success': 'O status foi modificado',
'newcasino.admin.user.popup.switchstatus.title': 'Alterar status do usuário',
'newcasino.admin.user.popup.switchuser.button.add': 'Atualizar',
'newcasino.admin.user.popup.switchuser.button.cancel': 'Cancelar',
'newcasino.admin.user.popup.switchuser.combo.label': 'Tipo de Usuário',
'newcasino.admin.user.popup.switchuser.subtitle': 'Selecione o novo tipo de usuário',
'newcasino.admin.user.popup.switchuser.success': 'O tipo de usuário foi modificado',
'newcasino.admin.user.popup.switchuser.title': 'Alterar tipo de usuário',
'newcasino.admin.user.popup.tx.cancel': 'Fechar',
'newcasino.admin.user.popup.tx.title': 'Detalhes da Transação',
'newcasino.admin.user.popup.wager.amount.hint': 'Quantia',
'newcasino.admin.user.popup.wager.amount.label': 'Quantia',
'newcasino.admin.user.popup.wager.button.add': 'Adicionar',
'newcasino.admin.user.popup.wager.button.cancel': 'Cancelar',
'newcasino.admin.user.popup.wager.expiration': 'Data de Expiração',
'newcasino.admin.user.popup.wager.list.hint': 'Um Email/Id de Usuário por linha',
'newcasino.admin.user.popup.wager.list.label': 'Lista de e-mail/Id de Usuário',
'newcasino.admin.user.popup.wager.max.hint': 'Valor máximo de ganho',
'newcasino.admin.user.popup.wager.max.label': 'Valor máximo de ganho',
'newcasino.admin.user.popup.wager.streamer': 'É uma aposta de streamer',
'newcasino.admin.user.popup.wager.subtitle': 'Fornecer informações sobre apostas',
'newcasino.admin.user.popup.wager.title': 'Adicionar Aposta',
'newcasino.admin.user.popup.wager.x.hint': 'X vezes',
'newcasino.admin.user.popup.wager.x.label': 'Rotatividade de dinheiro',
'newcasino.admin.user.update.success': 'As informações do usuário foram modificadas.',
'newcasino.admin.user.wallet.add.freespin': '+/- Rodadas grátis',
'newcasino.admin.user.wallet.add.freespin.info': 'Info Rodadas grátis',
'newcasino.admin.user.wallet.add.money': '+/- Dinheiro real',
'newcasino.admin.user.wallet.add.wager': 'Adicionar Aposta',
'newcasino.admin.user.wallet.col1': 'Tipo',
'newcasino.admin.user.wallet.col2': 'Estado',
'newcasino.admin.user.wallet.col3': 'Saldo',
'newcasino.admin.user.wallet.col4': 'Meta de Aposta',
'newcasino.admin.user.wallet.col5': 'Aposta Win Bal.',
'newcasino.admin.user.wallet.col6': 'Giro grátis',
'newcasino.admin.user.wallet.freespin.info.nodata': 'Não há viagens gratuitas esperando com o provedor',
'newcasino.admin.user.wallet.state.3xpending': 'Bônus 3x pendente',
'newcasino.admin.user.wallet.state.active': 'Ativo',
'newcasino.admin.user.wallet.state.inactive': 'Inativo',
'newcasino.admin.user.wallet.state.unknow': 'Desconhecido',
'newcasino.admin.user.wallet.state.unplayable': 'Não jogável',
'newcasino.admin.user.wallet.tx.col1': 'Data',
'newcasino.admin.user.wallet.tx.col2': 'Tipo',
'newcasino.admin.user.wallet.tx.col3': 'Quantia',
'newcasino.admin.user.wallet.tx.col4': 'Equilíbrio',
'newcasino.admin.user.wallet.tx.title': 'Transações da carteira',
'newcasino.admin.user.wallet.type.realmoney': 'Dinheiro real',
'newcasino.admin.user.wallet.type.streamer.wager': 'Aposta do Streamer',
'newcasino.admin.user.wallet.type.unknow': 'Desconhecido',
'newcasino.admin.user.wallet.type.wager': 'Aposta',
'newcasino.admin.user.wallet.wallets.title': 'Carteiras do Usuário',
'newcasino.admin.userlist.col1': 'Nome',
'newcasino.admin.userlist.col2': 'E-mail',
'newcasino.admin.userlist.col3': 'Opções',
'newcasino.admin.userlist.download': 'Download iniciado',
'newcasino.admin.userlist.export': 'Exportar para CSV',
'newcasino.admin.userlist.list.filters': 'Filtros',
'newcasino.admin.userlist.list.filters.banned': 'Banido',
'newcasino.admin.userlist.list.filters.kyc': 'KYC pendente',
'newcasino.admin.userlist.list.filters.streamers': 'Somente streamers',
'newcasino.admin.userlist.list.noresult': 'Nenhum resultado',
'newcasino.admin.userlist.list.title': 'Lista de jogadores',
'newcasino.admin.userlist.search': 'Pesquisar usuários',
'newcasino.admin.userlist.title': 'Gerenciamento de usuários',
'newcasino.admin.wager.add.success': 'Foi criada uma carteira de apostas com um valor de {AMOUNT} - {X}x',
'newcasino.aff.affiliates.approve.fail': 'As tentativas de aprovação do registro falharam.',
'newcasino.aff.affiliates.approve.pass': 'Usuário aprovado com sucesso.',
'newcasino.aff.affiliates.get.list.fail': 'As tentativas de recuperar a lista de afiliados falharam.',
'newcasino.aff.affiliates.pending.col.action': 'Ação',
'newcasino.aff.affiliates.pending.col.approvalDate': 'Aprovação',
'newcasino.aff.affiliates.pending.col.avg': 'Média de visualizações',
'newcasino.aff.affiliates.pending.col.email': 'Endereço de email',
'newcasino.aff.affiliates.pending.col.name': 'Nome',
'newcasino.aff.affiliates.pending.col.twitch': 'Twitch',
'newcasino.aff.affiliates.reject.fail': 'As tentativas de rejeitar o cadastro falharam.',
'newcasino.aff.affiliates.reject.pass': 'Usuário rejeitado com sucesso',
'newcasino.aff.auth.footer.contact': 'Entre em contato',
'newcasino.aff.auth.footer.copy': '© Copyright 2024 Numbers Game. Todos os direitos reservados.',
'newcasino.aff.auth.footer.policy': 'Política de Privacidade',
'newcasino.aff.auth.footer.terms': 'Termos e Condições',
'newcasino.aff.auth.forget.button.back': 'Voltar',
'newcasino.aff.auth.forget.button.reset': 'Redefinir Senha',
'newcasino.aff.auth.forget.fail': 'Impossível enviar o e-mail, por favor entre em contato conosco.',
'newcasino.aff.auth.forget.sub.title': 'Sem problemas, digite seu e-mail e você receberá um link para realizar a redefinição.',
'newcasino.aff.auth.forget.success': 'Verifique seu e-mail!',
'newcasino.aff.auth.forget.title': 'Você esqueceu sua senha?',
'newcasino.aff.auth.login.button.login': 'Entrar',
'newcasino.aff.auth.login.button.register': 'Cadastro',
'newcasino.aff.auth.login.email': 'Endereço de email',
'newcasino.aff.auth.login.email.err': 'E-mail inválido',
'newcasino.aff.auth.login.email.hint': 'Digite seu e-mail aqui',
'newcasino.aff.auth.login.err.fail': 'Nome de usuário ou senha inválidos.',
'newcasino.aff.auth.login.forget': 'Esqueceu sua senha?',
'newcasino.aff.auth.login.password': 'Senha',
'newcasino.aff.auth.login.stay': 'Mantenha-se conectado',
'newcasino.aff.auth.login.sub.title': 'Insira as informações necessárias para entrar ou cadastrar e abrir uma nova conta.',
'newcasino.aff.auth.login.title': 'Bem-vindo ao back-office do Numbers Game',
'newcasino.aff.auth.reg.cie': 'Nome da empresa (opcional)',
'newcasino.aff.auth.reg.cie.err': 'Inválido',
'newcasino.aff.auth.reg.country': 'País',
'newcasino.aff.auth.reg.country.hint': 'Escolha o seu país...',
'newcasino.aff.auth.reg.currency': 'Moeda',
'newcasino.aff.auth.reg.currency.hint': 'Selecione sua moeda...',
'newcasino.aff.auth.reg.dob': 'Data de nascimento',
'newcasino.aff.auth.reg.dob.err': 'Inválido - 18+',
'newcasino.aff.auth.reg.dob.hint': 'Ex.: dd/mm/aaaa',
'newcasino.aff.auth.reg.email': 'Endereço de email',
'newcasino.aff.auth.reg.email.err': 'E-mail inválido',
'newcasino.aff.auth.reg.err.fail': 'Impossível realizar o cadastro, por favor entre em contato conosco.',
'newcasino.aff.auth.reg.fullname': 'Nome completo',
'newcasino.aff.auth.reg.fullname.err': 'Inválido',
'newcasino.aff.auth.reg.password': 'Senha',
'newcasino.aff.auth.reg.password.err': 'Você precisa de pelo menos 8 caracteres, com uma combinação de letras maiúsculas e minúsculas.',
'newcasino.aff.auth.reg.password2': 'Confirme sua senha',
'newcasino.aff.auth.reg.password2.err': 'Não corresponde',
'newcasino.aff.auth.reg.streaming': 'Página de Streaming (opcional)',
'newcasino.aff.auth.reg.streaming.err': 'Inválido',
'newcasino.aff.auth.reg.sub.channel': 'Seus canais',
'newcasino.aff.auth.reg.sub.connection': 'Informações de conexão',
'newcasino.aff.auth.reg.sub.personnal': 'Informações pessoais',
'newcasino.aff.auth.reg.sub.title': 'Insira as informações necessárias para se cadastrar.',
'newcasino.aff.auth.reg.title': 'Cadastre-se',
'newcasino.aff.auth.reg.views': 'Média de visualizações (opcional)',
'newcasino.aff.auth.reg.views.err': 'Inválido',
'newcasino.aff.auth.reg.website': 'Site (opcional)',
'newcasino.aff.auth.reg.website.err': 'Inválido',
'newcasino.aff.commissions.calendar.1': 'Janeiro',
'newcasino.aff.commissions.calendar.10': 'Outubro',
'newcasino.aff.commissions.calendar.11': 'Novembro',
'newcasino.aff.commissions.calendar.12': 'Dezembro',
'newcasino.aff.commissions.calendar.2': 'Fevereiro',
'newcasino.aff.commissions.calendar.3': 'Março',
'newcasino.aff.commissions.calendar.4': 'Abril',
'newcasino.aff.commissions.calendar.5': 'Maio',
'newcasino.aff.commissions.calendar.6': 'Junho',
'newcasino.aff.commissions.calendar.7': 'Julho',
'newcasino.aff.commissions.calendar.8': 'Agosto',
'newcasino.aff.commissions.calendar.9': 'Setembro',
'newcasino.aff.commissions.detail.amount': 'Quantia',
'newcasino.aff.commissions.detail.comm': 'Comissão',
'newcasino.aff.commissions.detail.fixed': 'Quantia fixa',
'newcasino.aff.commissions.detail.ftd': 'FTD',
'newcasino.aff.commissions.detail.ftd.no': 'NÃO',
'newcasino.aff.commissions.detail.ftd.yes': 'SIM',
'newcasino.aff.commissions.detail.no': 'Nenhuma regra de comissão definida para este usuário',
'newcasino.aff.commissions.detail.perc': 'Porcentagem',
'newcasino.aff.commissions.detail.percdeposit': '% Depósito',
'newcasino.aff.commissions.detail.percvault': '% Cofre',
'newcasino.aff.commissions.detail.subtotal': 'Subtotal',
'newcasino.aff.commissions.detail.total': 'Total',
'newcasino.aff.commissions.detail.type': 'Tipo de comissão',
'newcasino.aff.commissions.grid.currency': 'Moeda da grade:',
'newcasino.aff.commissions.grid.maxWin': 'Ganho máximo total:',
'newcasino.aff.commissions.grid.title': 'Porcentagem de pagamento do cofre com base no depósito',
'newcasino.aff.commissions.grid.unlimited': 'Ilimitado',
'newcasino.aff.commissions.load.fail': 'Impossível carregar comissões, entre em contato com o suporte!',
'newcasino.aff.commissions.nopay.button': 'Lixo',
'newcasino.aff.commissions.notpaid.button': 'Não pagou ainda',
'newcasino.aff.commissions.paid.button': 'Pago',
'newcasino.aff.commissions.paid.total': 'Comissão Paga:',
'newcasino.aff.commissions.pay.button': 'Pagar',
'newcasino.aff.commissions.pay.convRate': 'Taxa de Conversão',
'newcasino.aff.commissions.pay.fixed': 'Valor Fixo Mensal',
'newcasino.aff.commissions.pay.minftd': 'Min. FTD',
'newcasino.aff.commissions.pay.paymCurrency': 'Cálculo realizado na moeda:',
'newcasino.aff.commissions.pay.paymDate': 'Data de pagamento:',
'newcasino.aff.commissions.pay.percDeposit': '% Depósito',
'newcasino.aff.commissions.pay.percVault': '% Cofre',
'newcasino.aff.commissions.pay.success': 'O streamer foi pago.',
'newcasino.aff.commissions.pay.total': 'Comissão Total:',
'newcasino.aff.commissions.pay.usegrid': 'Usar a Grade',
'newcasino.aff.commissions.refused.button': 'Recusou',
'newcasino.aff.commissions.title': 'Comissões',
'newcasino.aff.commissions.trash.success': 'O streamer não foi pago.',
'newcasino.aff.contactus.add1': 'Primeiro Andar, Casa Millennium,',
'newcasino.aff.contactus.add2': 'Victoria Road, Douglas, IM24RW,',
'newcasino.aff.contactus.add3': 'Ilha de Man',
'newcasino.aff.contactus.button.send': 'Envie sua mensagem',
'newcasino.aff.contactus.email': 'Qual é o seu email?',
'newcasino.aff.contactus.msg': 'Digite sua mensagem aqui',
'newcasino.aff.contactus.name': 'Qual o seu nome?',
'newcasino.aff.contactus.sub1': 'Tem uma pergunta? Entre em contato conosco.',
'newcasino.aff.contactus.sub2': 'Onde nos encontrar?',
'newcasino.aff.contactus.subject': 'Qual é o assunto?',
'newcasino.aff.contactus.success': 'Obrigado!Recebemos sua mensagem',
'newcasino.aff.contactus.title': 'Entre em Contato Conosco',
'newcasino.aff.dashboard.active.player': 'Jogador ativo',
'newcasino.aff.dashboard.commission.link': 'Ver todas as comissões',
'newcasino.aff.dashboard.dailygift.open': 'Número total de presentes diários abertos',
'newcasino.aff.dashboard.first.time.deposit.player': 'Depósito Inicial',
'newcasino.aff.dashboard.load.fail': 'Impossível carregar o painel, entre em contato com o suporte!',
'newcasino.aff.dashboard.period.lm': 'Mês passado',
'newcasino.aff.dashboard.period.lw': 'Semana passada',
'newcasino.aff.dashboard.period.ly': 'Ano passado',
'newcasino.aff.dashboard.period.tm': 'Este mês',
'newcasino.aff.dashboard.period.today': 'Hoje',
'newcasino.aff.dashboard.period.tw': 'Esta semana',
'newcasino.aff.dashboard.period.ty': 'Este ano',
'newcasino.aff.dashboard.period.yest': 'Ontem',
'newcasino.aff.dashboard.refId': 'Link de referência',
'newcasino.aff.dashboard.refId.copied': 'Copiado na área de transferência',
'newcasino.aff.dashboard.registration.total.player': 'Novos Jogadores',
'newcasino.aff.dashboard.sponsered.players': 'Número total de jogadores patrocinados',
'newcasino.aff.dashboard.title': 'Painel',
'newcasino.aff.dashboard.total.10.commission': 'Comissões Pagas',
'newcasino.aff.dashboard.total.deposit': 'Depósito Total',
'newcasino.aff.dashboard.total.deposit.graph.last7Days': 'Depósitos últimos dias',
'newcasino.aff.dashboard.total.deposit.graph.last7SameDay': 'Últimos {DAY}',
'newcasino.aff.dashboard.total.deposit.graph.last7Weeks': 'Depósitos nas últimas semanas',
'newcasino.aff.menuleft.affiliates': 'Afiliados',
'newcasino.aff.menuleft.commissions': 'Comissões',
'newcasino.aff.menuleft.copyright1': '© Copyright 2024 Numbers Game',
'newcasino.aff.menuleft.copyright2': 'Todos os direitos reservados.',
'newcasino.aff.menuleft.dashboard': 'Painel',
'newcasino.aff.policy.button': 'Fechar',
'newcasino.aff.policy.title': 'Política de Privacidade',
'newcasino.aff.regsuccess.button': 'Continuar',
'newcasino.aff.regsuccess.msg': 'Obrigado por se cadastrar conosco! Um dos nossos gerentes de afiliados entrará em contato com você dentro de 48 horas.',
'newcasino.aff.regsuccess.title': 'Bem-vindo!',
'newcasino.aff.terms.button': 'Fechar',
'newcasino.aff.terms.title': 'Termos e Condições',
'newcasino.aff.userlist.approve': 'Aprovar',
'newcasino.aff.userlist.approved': 'Membros',
'newcasino.aff.userlist.emtpy': 'Nenhum usuário para mostrar',
'newcasino.aff.userlist.pending': 'Aprovação Pendente',
'newcasino.aff.userlist.reject': 'Rejeitar',
'newcasino.aff.userlist.sub.title': 'Lista de afiliados',
'newcasino.aff.userlist.title': 'Afiliados',
'newcasino.auth.log.error.badcredential': 'E-mail ou senha incorretos',
'newcasino.auth.log.error.break': 'Você fez uma pausa no jogo! Você pode fazer login novamente em:',
'newcasino.auth.log.error.emailnotverified': 'Seu e-mail não foi verificado. Por favor, vá para sua caixa de entrada e clique no link de verificação. Se deseja receber outro e-mail de verificação, clique nesta mensagem.',
'newcasino.auth.log.error.unknown': 'Ops... Algo deu errado, por favor entre em contato com o suporte',
'newcasino.auth.log.msg.passreset.msg': 'Acabamos de enviar um e-mail contendo um link para redefinir sua senha.',
'newcasino.auth.log.msg.passreset.title': 'Verifique sua caixa de entrada!',
'newcasino.auth.log.msg.verifemail.msg': 'Acabamos de enviar um e-mail contendo um link para verificar seu endereço de e-mail.',
'newcasino.auth.log.msg.verifemail.title': 'Verifique sua caixa de entrada!',
'newcasino.auth.login.avail.freespin': 'Você tem rodadas grátis disponíveis, você pode usá-las em qualquer um dos jogos na aba "Rodadas Grátis" logo abaixo.',
'newcasino.auth.login.button.cancel': 'Cancelar',
'newcasino.auth.login.button.login': 'Entrar',
'newcasino.auth.login.button.noaccount': 'Não tem uma conta?',
'newcasino.auth.login.button.signup': 'Cadastre-se agora!',
'newcasino.auth.login.email': 'E-mail',
'newcasino.auth.login.email.err': 'E-mail inválido',
'newcasino.auth.login.forgot_password': 'Esqueceu sua senha?',
'newcasino.auth.login.password': 'Senha',
'newcasino.auth.login.reset.button.send': 'Enviar E-mail',
'newcasino.auth.login.reset.subTitle': 'Esqueceu sua senha? Sem problemas, nós enviaremos um e-mail para redefini-la.',
'newcasino.auth.login.reset.title': 'Redefinir senha',
'newcasino.auth.login.stayconnected': 'Permaneça conectado',
'newcasino.auth.login.subTitle': 'Bem-vindo ao Numbers Game! Informe seus dados de login',
'newcasino.auth.login.title': 'Conectar',
'newcasino.auth.login.type.email': 'Digite seu e-mail',
'newcasino.auth.login.type.password': 'Digite sua nova senha',
'newcasino.auth.registration.address.address.error': 'Endereço informado é muito curto',
'newcasino.auth.registration.address.address.hint': 'Digite seu endereço',
'newcasino.auth.registration.address.address.label': 'Endereço',
'newcasino.auth.registration.address.already': 'Já possui uma conta?',
'newcasino.auth.registration.address.back': 'Voltar',
'newcasino.auth.registration.address.city.error': 'O nome da sua cidade é muito curto',
'newcasino.auth.registration.address.city.hint': 'Digite sua cidade',
'newcasino.auth.registration.address.city.label': 'Cidade',
'newcasino.auth.registration.address.country.hint': 'Selecione seu país...',
'newcasino.auth.registration.address.country.label': 'País',
'newcasino.auth.registration.address.currency.hint': 'Selecione sua moeda...',
'newcasino.auth.registration.address.currency.label': 'Moeda',
'newcasino.auth.registration.address.next': 'Próxima etapa 4/5',
'newcasino.auth.registration.address.phone.error': 'Muito curto',
'newcasino.auth.registration.address.phone.hint': 'Número de telefone',
'newcasino.auth.registration.address.phone.label': 'Número de telefone',
'newcasino.auth.registration.address.phonecode.hint': 'Selecione o código do país...',
'newcasino.auth.registration.address.phonecode.label': 'Telefone - Código do país',
'newcasino.auth.registration.address.state.error': 'O nome do seu estado é muito curto',
'newcasino.auth.registration.address.state.hint': 'Estado',
'newcasino.auth.registration.address.state.label': 'Estado',
'newcasino.auth.registration.address.subTitle': 'Insira as informações necessárias para se registrar.',
'newcasino.auth.registration.address.title': 'Cadastro',
'newcasino.auth.registration.address.zip.error': 'O CEP é muito curto',
'newcasino.auth.registration.address.zip.hint': 'CEP',
'newcasino.auth.registration.address.zip.label': 'CEP',
'newcasino.auth.registration.bonus.already': 'Já possui uma conta?',
'newcasino.auth.registration.bonus.already.link': 'Conecte-se',
'newcasino.auth.registration.bonus.cancel': 'Cancelar',
'newcasino.auth.registration.bonus.cashback': 'Ganhe 3 vezes o valor do seu primeiro depósito, até $1500 em bônus',
'newcasino.auth.registration.bonus.cashback.subtitle': '*Requisito de apostas de 35x',
'newcasino.auth.registration.bonus.next': 'Próxima etapa 1/5',
'newcasino.auth.registration.bonus.none': 'Sem bônus',
'newcasino.auth.registration.bonus.promocode': 'Tenho código promocional!',
'newcasino.auth.registration.bonus.refer': 'Indique um Amigo',
'newcasino.auth.registration.bonus.refer.desc': 'Deposite e jogue um mínimo de 30$ e você e seu amigo receberão 15 rodadas grátis!',
'newcasino.auth.registration.bonus.refer2': 'Prossiga para a próxima etapa ou escolha uma promoção.',
'newcasino.auth.registration.bonus.refered': 'Seu amigo indicou você!',
'newcasino.auth.registration.bonus.subTitle': 'Bem-vindo ao Numbers Game! Escolha um bônus de boas-vindas para continuar.',
'newcasino.auth.registration.bonus.title': 'Cadastro',
'newcasino.auth.registration.completed.next': 'Vá para a página de login',
'newcasino.auth.registration.completed.text1': 'Você teve sucesso.',
'newcasino.auth.registration.completed.text2': 'completou seu cadastro',
'newcasino.auth.registration.completed.text3': 'Agora você pode acessar sua conta e fazer depósitos.',
'newcasino.auth.registration.completed.text4': 'Observe!',
'newcasino.auth.registration.completed.text5': 'Você deve preencher seu formulário KYC para fazer a retirada. Você pode fazer isso em seu perfil.',
'newcasino.auth.registration.completed.title': 'Registro concluído',
'newcasino.auth.registration.cred.already': 'Já tem uma conta?',
'newcasino.auth.registration.cred.back': 'Voltar',
'newcasino.auth.registration.cred.email.error': 'E-mail inválido',
'newcasino.auth.registration.cred.email.hint': 'Digite seu e-mail aqui',
'newcasino.auth.registration.cred.email.label': 'E-mail',
'newcasino.auth.registration.cred.license1': 'Estou de acordo com',
'newcasino.auth.registration.cred.license2': 'termos e condições',
'newcasino.auth.registration.cred.license3': 'e',
'newcasino.auth.registration.cred.license4': 'política de privacidade *',
'newcasino.auth.registration.cred.next': 'Próxima etapa 2/5',
'newcasino.auth.registration.cred.password.button.submit': 'Enviar',
'newcasino.auth.registration.cred.password.error': 'Você precisa de pelo menos 8 caracteres, incluindo letras maiúsculas e minúsculas',
'newcasino.auth.registration.cred.password.hint': 'Digite sua nova senha aqui',
'newcasino.auth.registration.cred.password.label': 'Senha',
'newcasino.auth.registration.cred.promotion.error': 'Código promocional inválido',
'newcasino.auth.registration.cred.promotion.hint': 'Digite seu código promocional aqui',
'newcasino.auth.registration.cred.promotion.label': 'Código Promocional',
'newcasino.auth.registration.cred.subTitle': 'Insira as informações necessárias para se registrar.',
'newcasino.auth.registration.cred.title': 'Cadastro',
'newcasino.auth.registration.currency.australia': 'Dólar australiano',
'newcasino.auth.registration.currency.brazil': 'Real brasileiro',
'newcasino.auth.registration.currency.canada': 'Dólar canadense',
'newcasino.auth.registration.currency.europe': 'Euro europeu',
'newcasino.auth.registration.currency.usa': 'Dólar americano',
'newcasino.auth.registration.emailcode.already': 'Já tem uma conta?',
'newcasino.auth.registration.emailcode.back': 'Voltar',
'newcasino.auth.registration.emailcode.hint': 'Digite seu código aqui',
'newcasino.auth.registration.emailcode.label': 'Código',
'newcasino.auth.registration.emailcode.next': 'Completo',
'newcasino.auth.registration.emailcode.resend': 'Não recebi o e-mail',
'newcasino.auth.registration.emailcode.resend.link': 'Clique aqui para enviar novamente',
'newcasino.auth.registration.emailcode.subTitle': 'Enviamos um código de confirmação para o seu endereço de e-mail. Digite o código para continuar seu cadastro.',
'newcasino.auth.registration.emailcode.title': 'Cadastro',
'newcasino.auth.registration.mobilecode.already': 'Já tem uma conta?',
'newcasino.auth.registration.mobilecode.back': 'Voltar',
'newcasino.auth.registration.mobilecode.hint': 'Digite seu código aqui',
'newcasino.auth.registration.mobilecode.label': 'Código',
'newcasino.auth.registration.mobilecode.next': 'Próxima etapa 5/5',
'newcasino.auth.registration.mobilecode.resend': 'Não recebeu o sms?',
'newcasino.auth.registration.mobilecode.resend.link': 'Clique aqui para enviar novamente',
'newcasino.auth.registration.mobilecode.subTitle': 'Enviamos um código de confirmação para o seu número de telefone. Digite o código para concluir seu cadastro.',
'newcasino.auth.registration.mobilecode.title': 'Cadastro',
'newcasino.auth.registration.userinfo.already': 'Já tem uma conta?',
'newcasino.auth.registration.userinfo.back': 'Voltar',
'newcasino.auth.registration.userinfo.birthday.error': 'Inválido - 18+',
'newcasino.auth.registration.userinfo.birthday.hint': 'Ex.: dd/mm/aaaa',
'newcasino.auth.registration.userinfo.birthday.label': 'Data de Nascimento',
'newcasino.auth.registration.userinfo.firstname.error': 'Nome informado é muito curto',
'newcasino.auth.registration.userinfo.firstname.hint': 'Digite seu nome aqui',
'newcasino.auth.registration.userinfo.firstname.label': 'Nome',
'newcasino.auth.registration.userinfo.lastname.error': 'Sobrenome informado é muito curto',
'newcasino.auth.registration.userinfo.lastname.hint': 'Digite seu sobrenome aqui',
'newcasino.auth.registration.userinfo.lastname.label': 'Sobrenome',
'newcasino.auth.registration.userinfo.next': 'Próxima etapa 3/5',
'newcasino.auth.registration.userinfo.subTitle': 'Insira as informações necessárias para se cadastrar.',
'newcasino.auth.registration.userinfo.title': 'Cadastro',
'newcasino.batch.admin.button.wager': 'Adicionar Aposta',
'newcasino.batch.admin.title': 'Tarefas em lote',
'newcasino.birthday.gift': 'FELIZ ANIVERSÁRIO!!! No seu aniversário, oferecemos {QTE} rodadas grátis.',
'newcasino.cashier.history.col1': 'Data',
'newcasino.cashier.history.col2': 'Tipo de Transação',
'newcasino.cashier.history.col3': 'Método',
'newcasino.cashier.history.col4': 'Quantia',
'newcasino.cashier.history.col5': 'Status',
'newcasino.cashier.history.subTitle': 'Estas são todas as suas transações nos últimos 30 dias',
'newcasino.cashier.history.title': 'Histórico de transações',
'newcasino.cashier.history.tx.cancelled': 'Cancelada',
'newcasino.cashier.history.tx.completed': 'Concluída',
'newcasino.cashier.history.tx.deposit': 'Depósito',
'newcasino.cashier.history.tx.pending': 'Pendente',
'newcasino.cashier.history.tx.withdrawal': 'Cancelamento',
'newcasino.cashier.subtitle': 'Aqui você pode realizar depósitos e retiradas de dinheiro.',
'newcasino.cashier.tab.back': 'Voltar',
'newcasino.cashier.tab.history': 'Histórico de transações',
'newcasino.cashier.title': 'CAIXA',
'newcasino.cashier.vault': 'Cofre',
'newcasino.contactus.button.send': 'Enviar Mensagem',
'newcasino.contactus.captcha': 'Você deve marcar a caixa de verificação Captcha',
'newcasino.contactus.email.error': 'Endereço de e-mail inválido',
'newcasino.contactus.failure': 'A mensagem não pôde ser enviada',
'newcasino.contactus.message': 'Mensagem',
'newcasino.contactus.message.error': 'Mensagem muito curta',
'newcasino.contactus.message.hint': 'Mensagem',
'newcasino.contactus.name': 'Nome completo',
'newcasino.contactus.name.email': 'E-mail',
'newcasino.contactus.name.email.hint': 'Digite seu e-mail aqui',
'newcasino.contactus.name.error': 'Nome muito curto',
'newcasino.contactus.name.hint': 'Digite seu nome aqui',
'newcasino.contactus.question': 'TEM UMA PERGUNTA? ENTRE EM CONTATO CONOSCO!',
'newcasino.contactus.subject': 'Assunto',
'newcasino.contactus.subject.error': 'Assunto é muito curto',
'newcasino.contactus.subject.hint': 'Assunto',
'newcasino.contactus.success': 'Obrigado, recebemos sua mensagem',
'newcasino.contactus.title': 'Entre em Contato',
'newcasino.contactus.where': 'ONDE NOS ENCONTRAR?',
'newcasino.contests.admin.detail.active': 'Ativo',
'newcasino.contests.admin.detail.bullet.en': 'Inglês Rápido',
'newcasino.contests.admin.detail.bullet.en.hint': 'Inglês',
'newcasino.contests.admin.detail.bullet.fr': 'Francês Rápido',
'newcasino.contests.admin.detail.bullet.fr.hint': 'Francês',
'newcasino.contests.admin.detail.bullet.pt': 'Português Rápido',
'newcasino.contests.admin.detail.bullet.pt.hint': 'Português',
'newcasino.contests.admin.detail.button.add': '+',
'newcasino.contests.admin.detail.button.delete': 'Excluir',
'newcasino.contests.admin.detail.button.save': 'Salvar',
'newcasino.contests.admin.detail.date.end': 'Data Final',
'newcasino.contests.admin.detail.date.end.hint': 'Ex: dd/mm/aaaa',
'newcasino.contests.admin.detail.date.start': 'Data de Início',
'newcasino.contests.admin.detail.date.start.hint': 'Ex: dd/mm/aaaa',
'newcasino.contests.admin.detail.desc.en': 'Descrição em Inglês',
'newcasino.contests.admin.detail.desc.en.hint': 'Digite a descrição em inglês',
'newcasino.contests.admin.detail.desc.fr': 'Descrição em Francês',
'newcasino.contests.admin.detail.desc.fr.hint': 'Digite a descrição em francês',
'newcasino.contests.admin.detail.desc.pt': 'Descrição em português',
'newcasino.contests.admin.detail.desc.pt.hint': 'Digite a descrição em português',
'newcasino.contests.admin.detail.header.title.missing': '???',
'newcasino.contests.admin.detail.header.winners': 'Os Vencedores',
'newcasino.contests.admin.detail.title.en': 'Título em Inglês',
'newcasino.contests.admin.detail.title.en.hint': 'Digite o título em inglês',
'newcasino.contests.admin.detail.title.fr': 'Título em Francês',
'newcasino.contests.admin.detail.title.fr.hint': 'Digite o título em francês',
'newcasino.contests.admin.detail.title.pt': 'Título em português',
'newcasino.contests.admin.detail.title.pt.hint': 'Digite o título em português',
'newcasino.contests.admin.detail.win.button.add': '+',
'newcasino.contests.admin.detail.win.date': 'Data',
'newcasino.contests.admin.detail.win.date.hint': 'Ex: dd/mm/aaaa',
'newcasino.contests.admin.detail.win.name': 'Nome do vencedor',
'newcasino.contests.admin.detail.win.name.hint': 'Digite o nome do vencedor',
'newcasino.contests.admin.detail.win.prize.en': 'Prêmio em Inglês',
'newcasino.contests.admin.detail.win.prize.en.hint': 'Inglês',
'newcasino.contests.admin.detail.win.prize.fr': 'Prêmio em francês',
'newcasino.contests.admin.detail.win.prize.fr.hint': 'Francês',
'newcasino.contests.admin.detail.win.prize.pt': 'Prêmio em português',
'newcasino.contests.admin.detail.win.prize.pt.hint': 'Português',
'newcasino.contests.admin.list.add.button': 'Adicionar Novo Concurso',
'newcasino.contests.admin.list.col1': 'Data do Concurso',
'newcasino.contests.admin.list.col2': 'Nome do Concurso',
'newcasino.contests.admin.list.col3': 'Exibir para usuários',
'newcasino.contests.admin.list.no': 'Não',
'newcasino.contests.admin.list.title': 'Gerenciamento de Concursos',
'newcasino.contests.admin.list.yes': 'Sim',
'newcasino.contests.other': 'Outro(s) concurso(s)',
'newcasino.contests.winner.no': 'Vencedor do concurso nº.',
'newcasino.contests.winner.none': 'Ainda não há vencedor!',
'newcasino.contests.winner.none2': 'Os futuros vencedores aparecerão aqui',
'newcasino.contests.winner.of': 'Vencedor de',
'newcasino.days.1': 'Domingos',
'newcasino.days.2': 'Segundas-feiras',
'newcasino.days.3': 'Terças-feiras',
'newcasino.days.4': 'Quartas-feiras',
'newcasino.days.5': 'Quintas-feiras',
'newcasino.days.6': 'Sextas-feiras',
'newcasino.days.7': 'Sábados',
'newcasino.error.404': 'A página solicitada não foi encontrada',
'newcasino.error.500': 'Erro no servidor.Tente novamente em alguns instantes',
'newcasino.error.contact.support': 'Ocorreu um erro inesperado. Por favor, entre em contato com o suporte.',
'newcasino.error.delete.contest': 'Erro ao tentar excluir o concurso',
'newcasino.error.delete.popup': 'Operação Falhou',
'newcasino.error.email.send.failed': 'Não foi possível enviar um código de verificação para o seu endereço de e-mail. Por favor, entre em contato com o suporte.',
'newcasino.error.email.send.success': 'O e-mail foi enviado novamente. Por favor, verifique seu spam se não o encontrar.',
'newcasino.error.email.validation.failed': 'Este código é inválido. Por favor tente novamente.',
'newcasino.error.fetching.contest': 'Erro ao tentar obter informações dos concursos',
'newcasino.error.fetching.popup': 'Erro ao tentar obter informações do popup',
'newcasino.error.game.not.available': 'Jogo não disponível',
'newcasino.error.game.not.available.msg': 'Este jogo não está acessível no momento',
'newcasino.error.need.account': 'Conta Necessária',
'newcasino.error.need.account.to.play': 'Você precisa fazer login ou se cadastrar para acessar este jogo',
'newcasino.error.no.contest': 'Não há concurso no momento.',
'newcasino.error.save.contest': 'Erro ao tentar salvar o concurso',
'newcasino.error.save.popup': 'Operação Falhou',
'newcasino.error.sms.send.failed': 'Não foi possível enviar um código de verificação para o seu número de telefone. Entre em contato com o suporte.',
'newcasino.error.sms.send.success': 'O sms foi reenviado para o seu telefone',
'newcasino.error.sms.validation.failed': 'Este código é inválido, tente novamente',
'newcasino.error.title': 'Erro',
'newcasino.error.user.creation.failed': 'Este e-mail já está sendo utilizado. Se for seu, você pode redefinir a senha.',
'newcasino.faq.a1': "Clique na aba de login a partir do botão de perfil e, em seguida, em 'Esqueci a senha?'. A partir daí, siga os passos e você poderá recuperar sua senha.",
'newcasino.faq.a2': 'Você pode alterar sua senha a qualquer momento. Basta fazer login em sua conta, ir para a página "Editar Perfil" e clicar em "Alterar senha". Você precisará inserir sua senha antiga junto com a sua nova senha.',
'newcasino.faq.a3': 'Todos os principais cartões de crédito, cartões pré-pagos e criptomoedas.',
'newcasino.faq.a4': 'Algumas instituições financeiras não aceitam transações provenientes de websites de "E-Gaming"; portanto, você deve tentar um cartão ou método de pagamento diferente. A maioria dos cartões de crédito com programas de recompensa em dinheiro provavelmente não funcionará no site.',
'newcasino.faq.a5': 'Ao acessar o Numbers Game, um pop-up de notificação aparecerá perguntando se você deseja adicionar à sua tela inicial. Basta clicar nesse link e o ícone do Numbers Game aparecerá na tela inicial do seu telefone.',
'newcasino.faq.contact': 'Entre em Contato',
'newcasino.faq.hesitate': 'Não hesite em entrar em contato conosco para mais informações.',
'newcasino.faq.more': 'MAIS PERGUNTAS?',
'newcasino.faq.q1': 'O que faço se esquecer a senha da minha conta?',
'newcasino.faq.q2': 'Posso alterar minha senha?',
'newcasino.faq.q3': 'Quais métodos de pagamento posso usar para fazer um depósito?',
'newcasino.faq.q4': 'Meu cartão de crédito foi recusado por um motivo desconhecido, o que devo fazer?',
'newcasino.faq.q5': 'Eu quero acessar o Numbers Game mais rapidamente. O que posso fazer?',
'newcasino.faq.title': 'Perguntas Frequentes',
'newcasino.footer.col1.address': 'Primeiro Andar, Millennium House, Victoria Road, Douglas, IM24RW, Ilha de Man',
'newcasino.footer.col1.contactus': 'Entre em contato',
'newcasino.footer.col2.learnmore': 'Saiba mais',
'newcasino.footer.col2.text': 'O Numbers Games é de propriedade da GAC Group Limited e operado sob a Licença de Jogos de Kahnawake da GAC Group Limited, uma empresa incorporada na Ilha de Man.',
'newcasino.footer.col2.title': 'Sobre nós',
'newcasino.footer.col3.link.aboutus': 'Sobre nós',
'newcasino.footer.col3.link.casino': 'Cassino',
'newcasino.footer.col3.link.contact': 'Contato',
'newcasino.footer.col3.link.home': 'Página Inicial',
'newcasino.footer.col3.link.livecasino': 'Cassino ao Vivo',
'newcasino.footer.col3.link.login': 'Conectar',
'newcasino.footer.col3.link.register': 'Registro',
'newcasino.footer.col3.title': 'Numbers Game',
'newcasino.footer.col4.link.affiliate': 'Afiliados',
'newcasino.footer.col4.link.betting': 'Regras de Apostas',
'newcasino.footer.col4.link.bonusterms': 'Termos de Bônus',
'newcasino.footer.col4.link.faq': 'Perguntas Frequentes',
'newcasino.footer.col4.link.privacy': 'Política de Privacidade',
'newcasino.footer.col4.link.terms': 'Termos e Condições',
'newcasino.footer.col4.title': 'Links importantes',
'newcasino.footer.copy.line1': 'Numbers Games é de propriedade da GAC Group Limited e operado sob a Licença de Jogos de Kahnawake da GAC Group Limited, uma empresa incorporada na Ilha de Man.',
'newcasino.footer.copy.line2': '© Copyright 2024 Numbers Game. Todos os direitos reservados.',
'newcasino.game.popup.BET_TOO_HIGH': 'Licitação recusada! A aposta máxima para este jogo é {MAXBET}.',
'newcasino.game.popup.DEPOSIT_LIMIT_1000': 'Com este valor você excede seu limite de depósito diário',
'newcasino.game.popup.DEPOSIT_LIMIT_1001': 'Com este valor você excede seu limite de depósito semanal',
'newcasino.game.popup.DEPOSIT_LIMIT_1002': 'Com este valor você excede seu limite de depósito mensal',
'newcasino.game.popup.LOST_LIMIT_REACHED': 'Licitação recusada! Com esta aposta você excederá seu limite de perda. Jogue com cautela.',
'newcasino.game.popup.MAINTENANCE': 'O cassino está manutenção, volte mais tarde para continuar seu jogo.',
'newcasino.game.popup.STREAMER_WAGER_WIN': 'Parabéns! Você cumpriu com sucesso o requisito de aposta. O saldo foi transferido para o seu cofre.',
'newcasino.game.popup.WAGER_LOSE': 'Mais sorte da próxima vez! Você atingiu o nível mínimo do saldo de aposta. Esta carteira será removida dentro de 24 horas.',
'newcasino.game.popup.WAGER_WIN': 'Parabéns! Você atendeu com sucesso ao requisito de aposta. O saldo foi transferido para sua carteira pessoal.',
'newcasino.games.category.code_all_slots': 'Todos os Caça-Níqueis',
'newcasino.games.category.code_baccarat': 'Baccarat',
'newcasino.games.category.code_blackjack': 'BlackJack',
'newcasino.games.category.code_buy_feature': 'Comprar Recurso',
'newcasino.games.category.code_crash': 'Crash Games',
'newcasino.games.category.code_game_shows': 'Game Shows',
'newcasino.games.category.code_instant_win': 'Ganho Instantâneo',
'newcasino.games.category.code_live_casino': 'Cassino ao vivo',
'newcasino.games.category.code_lottery': 'Loteria',
'newcasino.games.category.code_mega_slots': 'Mega Caça-Níqueis',
'newcasino.games.category.code_more_games': 'Mais Jogos',
'newcasino.games.category.code_multiplayer': 'Multijogador',
'newcasino.games.category.code_our_selection': 'Nossa Seleção',
'newcasino.games.category.code_roulette': 'Roleta',
'newcasino.games.category.code_scratch_card': 'Raspadinha',
'newcasino.games.category.code_slots': 'Caça-Níqueis',
'newcasino.games.category.code_tables_cards': 'Mesas & Cartas',
'newcasino.games.category.code_top_favorites': 'Principais Favoritos',
'newcasino.games.category.code_top_games': 'Principais Jogos',
'newcasino.games.category.code_top_live': 'Principais Ao Vivo',
'newcasino.games.category.code_video': 'Vídeo',
'newcasino.games.category.code_video_bingo_keno': 'Vídeo Bingo e Keno',
'newcasino.games.category.code_virtual_sports': 'Esportes Virtuais',
'newcasino.games.category.code_win_or_crash': 'Ganhar ou Perder',
'newcasino.games.newgame': 'Novos Jogos',
'newcasino.games.subtype.code_baccara': 'Bacará',
'newcasino.games.subtype.code_baccarat': 'Bacará',
'newcasino.games.subtype.code_blackjack': 'BlackJack',
'newcasino.games.subtype.code_craps': 'Jogos de Dados',
'newcasino.games.subtype.code_crash': 'Crash Games',
'newcasino.games.subtype.code_live': 'Jogos ao Vivo',
'newcasino.games.subtype.code_others': 'Outros',
'newcasino.games.subtype.code_roulette': 'Roleta',
'newcasino.games.subtype.code_slot': 'Caça-Níqueis',
'newcasino.games.type.code_casino': 'Cassino',
'newcasino.games.type.code_livedealer': 'Dealers ao Vivo',
'newcasino.general.false': 'Falso',
'newcasino.general.true': 'Sim',
'newcasino.generic.error.msg': 'Algo deu errado!Por favor entre em contato com o suporte.',
'newcasino.giveaways.admin.none': 'Não há sorteio pendente',
'newcasino.giveaways.admin.save': 'Salvar',
'newcasino.giveaways.admin.title': 'Brindes do Streamer',
'newcasino.giveaways.amount.hint': 'Quantia',
'newcasino.giveaways.amount.label': 'Quantidade a oferecer',
'newcasino.giveaways.email.error': 'E-mail inválido',
'newcasino.giveaways.email.hint': 'Digite o e-mail aqui',
'newcasino.giveaways.email.label': 'E-mail do seguidor',
'newcasino.giveaways.failure.amount': 'Seu saldo para sorteios não é suficiente.',
'newcasino.giveaways.failure.email': 'O e-mail do seguidor é inválido',
'newcasino.giveaways.failure.wager': 'Parece que você já aproveitou sua oferta hoje!',
'newcasino.giveaways.success.message': 'A solicitação do sorteio foi concluída',
'newcasino.giveaways.success.wager': 'A nova carteira de apostas já está disponível',
'newcasino.header.home': 'NUMBERS GAME - CASSINO',
'newcasino.homegame.freespin': 'Rodadas grátis',
'newcasino.homegame.freespin.sublabel': 'Você tem {QTE} rodadas grátis que pode usar em um dos seguintes jogos',
'newcasino.homegame.live': 'Cassino ao Vivo',
'newcasino.homegame.lobby': 'Lobby',
'newcasino.homegame.providers': 'Fornecedores',
'newcasino.homegame.search': 'Buscar Cassino',
'newcasino.homegame.showall': 'Mostrar Tudo',
'newcasino.homegame.slots': 'Caça-Níqueis',
'newcasino.info.title': 'Informações',
'newcasino.loyalty.claim.success.freespin': 'Você acabou de ganhar {QTE} rodadas grátis. Na página principal, quando sua carteira de dinheiro real for selecionada, você verá uma aba de rodadas grátis mostrando todos os jogos onde você pode usá-las.',
'newcasino.loyalty.claim.success.loyalty': 'Você ganha {QTE} pontos PNG',
'newcasino.loyalty.claim.success.money': 'Você ganha {AMOUNT}',
'newcasino.loyalty.claim.success.none': 'A caixa está vazia, melhor sorte amanhã',
'newcasino.loyalty.claim.success.wager': 'Você ganha uma carteira de apostas bônus: {AMOUNT} {X}x - Ganho máximo: {MAXWIN}',
'newcasino.loyalty.daily.continue': 'Para continuar abrindo presentes diários no próximo mês, você terá que conseguir o mínimo',
'newcasino.loyalty.daily.enable': 'PNG aponta para ativar esse recurso',
'newcasino.loyalty.daily.first': 'O primeiro mês está aberto a todos. Você pode retirar o presente diário a partir de agora clicando nele.',
'newcasino.loyalty.daily.minimum': 'Você precisa atingir o mínimo',
'newcasino.loyalty.daily.na': 'Sua recompensa diária não está disponível este mês.',
'newcasino.loyalty.daily.nextin': 'Presente disponível em:',
'newcasino.loyalty.daily.no.kyc': 'Você deve enviar seus Documentos de Identidade em «Editar Perfil» e ser aprovado para começar a receber seus presentes diários.',
'newcasino.loyalty.daily.points': 'pontos',
'newcasino.loyalty.daily.timeleft': 'Tempo restante para reivindicar:',
'newcasino.loyalty.daily.title': 'Recompensa diária de login consecutivo',
'newcasino.loyalty.day1': 'Dia 1',
'newcasino.loyalty.day2': 'Dia 2',
'newcasino.loyalty.day3': 'Dia 3',
'newcasino.loyalty.day4': 'Dia 4',
'newcasino.loyalty.day5': 'Dia 5',
'newcasino.loyalty.day6': 'Dia 6',
'newcasino.loyalty.day7': 'Dia 7',
'newcasino.loyalty.gift.confirmation.title': 'Parabéns!!!',
'newcasino.loyalty.how': 'Como isso funciona?',
'newcasino.loyalty.how.para.1.title': 'Pontos de recompensa PNG',
'newcasino.loyalty.how.para.1.txt': 'Você pode trocar seus pontos por dinheiro real, rodadas grátis ou até mesmo dinheiro real com exigência de aposta.',
'newcasino.loyalty.how.para.2.title': 'Pontos de Progresso',
'newcasino.loyalty.how.para.2.txt': 'Os pontos de progresso determinam o nível VIP em que você se encontra.',
'newcasino.loyalty.how.para.3.title': 'Loja de pontos PNG',
'newcasino.loyalty.how.para.3.txt': 'Você pode trocar seus pontos de recompensa PNG aqui pela opção que melhor se adapta a você: dinheiro real, rodadas grátis ou dinheiro real com exigência de aposta.',
'newcasino.loyalty.how.para.3.txtextra': '*Se você trocar seus Pontos de Recompensa PNG por dinheiro real com um requisito de aposta, observe que o requisito de aposta diminui em 5X a cada novo nível VIP pelo mesmo preço.',
'newcasino.loyalty.how.para.4.title': 'Níveis VIP Numbers Game',
'newcasino.loyalty.how.para.4.txt': 'À medida que você sobe em cada nível, suas recompensas aumentarão, oferecendo prêmios cada vez mais atraentes a cada passo de sua jornada.',
'newcasino.loyalty.how.para.5.title': 'Recompensas de visitas diárias consecutivas',
'newcasino.loyalty.how.para.5.txt': 'Ao ganhar um mínimo de 50 pontos de progresso por mês, você desbloqueia suas recompensas de visitas diárias consecutivas para o mês seguinte. A cada dia que passa e à medida que você avança nos níveis VIP, recompensas superiores estarão disponíveis para você. Quanto mais você joga, melhor é o nível dos prêmios.',
'newcasino.loyalty.how.para.6.table.head.1': 'Por cada 5 EUR apostados',
'newcasino.loyalty.how.para.6.table.head.2': 'Pontos de recompensa PNG',
'newcasino.loyalty.how.para.6.table.head.3': 'Pontos de Progresso',
'newcasino.loyalty.how.para.6.table.value.1': 'Caça-níqueis',
'newcasino.loyalty.how.para.6.table.value.2': '1',
'newcasino.loyalty.how.para.6.table.value.3': '1',
'newcasino.loyalty.how.para.6.title': 'Como ganhar',
'newcasino.loyalty.how.para.7.table.head.1': 'Por cada 50 EUR apostados',
'newcasino.loyalty.how.para.7.table.head.2': 'Pontos de recompensa PNG',
'newcasino.loyalty.how.para.7.table.head.3': 'Pontos de Progresso',
'newcasino.loyalty.how.para.7.table.value.1': 'Jogos com crupiê ao vivo',
'newcasino.loyalty.how.para.7.table.value.2': '1',
'newcasino.loyalty.how.para.7.table.value.3': '1',
'newcasino.loyalty.how.para.7.table.value.4': 'Jogos de mesa',
'newcasino.loyalty.how.para.7.table.value.5': '1',
'newcasino.loyalty.how.para.7.table.value.6': '1',
'newcasino.loyalty.how.para.7.table.value.7': 'Crash Games',
'newcasino.loyalty.how.para.7.table.value.8': '1',
'newcasino.loyalty.how.para.7.table.value.9': '1',
'newcasino.loyalty.how.title': 'Como isso funciona?',
'newcasino.loyalty.level.1': 'Nível básico',
'newcasino.loyalty.level.1.color': '#E6824E',
'newcasino.loyalty.level.10': 'Nível IX',
'newcasino.loyalty.level.10.color': '#FFD700',
'newcasino.loyalty.level.11': 'Nível X',
'newcasino.loyalty.level.11.color': '#E5E4E2',
'newcasino.loyalty.level.2': 'Nível I',
'newcasino.loyalty.level.2.color': '#A1BFC2',
'newcasino.loyalty.level.3': 'Nível II',
'newcasino.loyalty.level.3.color': '#EACD5B',
'newcasino.loyalty.level.4': 'Nível III',
'newcasino.loyalty.level.4.color': '#C3A7D0',
'newcasino.loyalty.level.5': 'Nível IV',
'newcasino.loyalty.level.5.color': '#F5F5F5',
'newcasino.loyalty.level.6': 'Nível V',
'newcasino.loyalty.level.6.color': '#EB0BAC',
'newcasino.loyalty.level.7': 'Nível VI',
'newcasino.loyalty.level.7.color': '#b87333',
'newcasino.loyalty.level.8': 'Nível VII',
'newcasino.loyalty.level.8.color': '#b08d57',
'newcasino.loyalty.level.9': 'Nível VIII',
'newcasino.loyalty.level.9.color': '#C0C0C0',
'newcasino.loyalty.level.title': 'Nível VIP',
'newcasino.loyalty.mylevel': 'Meu nível',
'newcasino.loyalty.mypoint': 'Mes pontos',
'newcasino.loyalty.no.points': 'Você não tem pontos suficientes para comprar nada',
'newcasino.loyalty.progression': 'Progressão de PNG Points para habilitar recompensas diárias do próximo mês',
'newcasino.loyalty.progression.percent': 'Progressão para o próximo nível',
'newcasino.loyalty.shop.cancel': 'Cancelar',
'newcasino.loyalty.shop.category.1': 'Todos',
'newcasino.loyalty.shop.category.2': 'Dinheiro',
'newcasino.loyalty.shop.category.3': 'Apostas',
'newcasino.loyalty.shop.category.4': 'Rodadas grátis',
'newcasino.loyalty.shop.category.5': 'Tudo que posso pagar',
'newcasino.loyalty.shop.confirm': 'Confirme',
'newcasino.loyalty.shop.confirm.cash': 'Você acabou de comprar:',
'newcasino.loyalty.shop.confirm.freespin.1': 'Você acabou de comprar',
'newcasino.loyalty.shop.confirm.freespin.2': 'Rodadas grátis. Na página principal, quando sua carteira de dinheiro real for selecionada, você verá uma aba de rodadas grátis mostrando todos os jogos onde você pode usá-las.',
'newcasino.loyalty.shop.confirm.wager.1': 'Você acabou de comprar uma carteira de apostas de',
'newcasino.loyalty.shop.confirm.wager.2': 'com um requisito de',
'newcasino.loyalty.shop.confirm.wager.3': 'x.',
'newcasino.loyalty.shop.element.avail': 'Disponível',
'newcasino.loyalty.shop.element.cost': 'Custo:',
'newcasino.loyalty.shop.element.freespin': 'Rodadas grátis',
'newcasino.loyalty.shop.element.na': 'PTS insuficiente',
'newcasino.loyalty.shop.element.pts': 'PTS',
'newcasino.loyalty.shop.mainpage.button': 'Compre agora!',
'newcasino.loyalty.shop.mainpage.title.1': 'Rodadas grátis',
'newcasino.loyalty.shop.mainpage.title.2': 'Dinheiro',
'newcasino.loyalty.shop.mainpage.title.3': 'Apostas',
'newcasino.loyalty.shop.mainpage.txt.1': 'Sim!!! Você pode comprar rodadas grátis com seus pontos PNG',
'newcasino.loyalty.shop.mainpage.txt.2': 'Você pode converter seus pontos em dinheiro real!!!',
'newcasino.loyalty.shop.mainpage.txt.3': 'Carteiras de apostas também estão disponíveis. O fator x diminui em cada nível de fidelidade.',
'newcasino.loyalty.shop.no.kyc': 'Você deve enviar seus Documentos de Identidade em «Editar Perfil» e ser aprovado para começar a trocar seus pontos PNG.',
'newcasino.loyalty.shop.subtitle': 'Troque seus pontos PNG pelo bônus de sua escolha! Selecione uma recompensa e clique no botão confirmar.',
'newcasino.loyalty.shop.title': 'Loja de recompensas PNG',
'newcasino.loyalty.title': 'Recompensa PNG',
'newcasino.loyalty.vip.features.10percextra': '10% de pontos extras de recompensa PNG',
'newcasino.loyalty.vip.features.5xwb': '5x menos para aposta comprada',
'newcasino.loyalty.vip.features.birth': 'Presente de aniversário',
'newcasino.loyalty.vip.features.bonusGrowth': 'Crescimento de bônus',
'newcasino.loyalty.vip.features.dailyBonusReload': 'Bônus diários/recarga',
'newcasino.loyalty.vip.features.dr': 'Recompensas Diárias',
'newcasino.loyalty.vip.features.levelUpBonuses': 'Bônus de subida de nível',
'newcasino.loyalty.vip.features.monthlyBonuses': 'Bônus Mensais',
'newcasino.loyalty.vip.features.rakeback': 'Rakeback',
'newcasino.loyalty.vip.features.rs': 'Loja de recompensas PNG',
'newcasino.loyalty.vip.features.weeklyBonuses': 'Bônus semanais',
'newcasino.memorylist.next': 'Próxima Página',
'newcasino.memorylist.of': 'de',
'newcasino.memorylist.page': 'Página',
'newcasino.memorylist.previous': 'Página Anterior',
'newcasino.menu.wallets.bonus': 'Aposta',
'newcasino.menu.wallets.mywallet': 'Meu Dinheiro',
'newcasino.menu.wallets.streamer': 'Streamer',
'newcasino.menu.wallets.switch.ko': 'Não é possível trocar de carteira',
'newcasino.menu.wallets.switch.ok': 'Carteira Alterada',
'newcasino.menu.wallets.title': 'Suas Carteiras:',
'newcasino.menuAdmin.1': 'Gerenciamento de Usuários',
'newcasino.menuAdmin.2': 'Gerenciamento de Concursos',
'newcasino.menuAdmin.3': 'Pedidos',
'newcasino.menuAdmin.4': 'Streamers',
'newcasino.menuAdmin.5': 'Sorteios',
'newcasino.menuAdmin.6': 'Jogos',
'newcasino.menuAdmin.7': 'Gerenciamento de Pop-ups',
'newcasino.menuAdmin.8': 'Manutenção',
'newcasino.menuAdmin.9': 'Promoções',
'newcasino.menuAdmin.title': 'Menu do Administrador',
'newcasino.menuleft.3.101': 'Français',
'newcasino.menuleft.3.102': 'English',
'newcasino.menuleft.3.103': 'Português',
'newcasino.menuleft.3.2': 'Streamer',
'newcasino.menuleft.3.4': 'Jogo Responsável',
'newcasino.menuleft.3.5': 'Vencedores do Concurso',
'newcasino.menuleft.3.6': 'Indique um amigo (Aposta)',
'newcasino.menuleft.3.7': 'Fidelidade',
'newcasino.menuleft.category.3': 'Mais',
'newcasino.menuleft.darkmode': 'Modo Escuro',
'newcasino.menutop.button.1': 'Entrar',
'newcasino.menutop.button.2': 'Registro',
'newcasino.menutop.button.bank': '$--',
'newcasino.menutop.button.cashier': 'Caixa',
'newcasino.menutop.lang.en': 'Inglês',
'newcasino.menutop.lang.fr': 'Francês',
'newcasino.menutop.lang.pt': 'Português',
'newcasino.menutop.lang.title': 'Idioma',
'newcasino.menutop.maximize': 'Tela Cheia',
'newcasino.menutop.wallet': 'Escolha sua carteira',
'newcasino.mobileBottomMenu.element.1': 'Menu',
'newcasino.mobileBottomMenu.element.2': 'Página Inicial',
'newcasino.mobileBottomMenu.element.3': 'Caça-Níqueis',
'newcasino.mobileBottomMenu.element.4': 'Cassino ao Vivo',
'newcasino.mobileBottomMenu.element.5': 'Bate-papo ao vivo',
'newcasino.modal.msg.function.not.available.yet': 'Esta função ainda não está disponível',
'newcasino.modal.msg.info': 'Informação',
'newcasino.player.use.freespin.button.no': 'Não',
'newcasino.player.use.freespin.button.yes': 'Sim',
'newcasino.player.use.freespin.title': 'Rodadas Grátis Disponíveis',
'newcasino.player.use.freespin.txt1': 'Você tem',
'newcasino.player.use.freespin.txt2': 'rodadas grátis disponíveis. Você quer usá-los neste jogo?',
'newcasino.popups.admin.detail.active': 'Ativo',
'newcasino.popups.admin.detail.body.en': 'Conteúdo',
'newcasino.popups.admin.detail.body.en.hint': 'Digite o conteúdo em inglês',
'newcasino.popups.admin.detail.body.fr': 'Conteúdo',
'newcasino.popups.admin.detail.body.fr.hint': 'Digite o conteúdo em francês',
'newcasino.popups.admin.detail.body.pt': 'Conteúdo',
'newcasino.popups.admin.detail.body.pt.hint': 'Digite o conteúdo em português',
'newcasino.popups.admin.detail.button.delete': 'Excluir',
'newcasino.popups.admin.detail.button.save': 'Salvar',
'newcasino.popups.admin.detail.button.test': 'Teste Isso',
'newcasino.popups.admin.detail.date.end': 'Data Final',
'newcasino.popups.admin.detail.date.end.hint': 'Ex: dd/mm/aaaa',
'newcasino.popups.admin.detail.date.start': 'Data de Início',
'newcasino.popups.admin.detail.date.start.hint': 'Ex: dd/mm/aaaa',
'newcasino.popups.admin.detail.header.title.missing': '???',
'newcasino.popups.admin.detail.header.title.popup': 'Editor de Pop-up',
'newcasino.popups.admin.detail.height': 'Altura do Pop-up (px)',
'newcasino.popups.admin.detail.height.hint': 'Altura(padrão 100%)',
'newcasino.popups.admin.detail.html': 'Usar HTML',
'newcasino.popups.admin.detail.internal.name': 'Nome Interno',
'newcasino.popups.admin.detail.internal.name.hint': 'Digite o Nome Interno',
'newcasino.popups.admin.detail.receiver.admin': 'Administrador',
'newcasino.popups.admin.detail.receiver.affiliates': 'Afiliados',
'newcasino.popups.admin.detail.receiver.all': 'Todos',
'newcasino.popups.admin.detail.receiver.hint': 'Selecione o destinatário...',
'newcasino.popups.admin.detail.receiver.label': 'Destinatário',
'newcasino.popups.admin.detail.receiver.logged': 'Todos os usuários logados',
'newcasino.popups.admin.detail.receiver.partners': 'Parceiros',
'newcasino.popups.admin.detail.receiver.players': 'Jogadores',
'newcasino.popups.admin.detail.receiver.streamers': 'Streamers',
'newcasino.popups.admin.detail.tab.english': 'Inglês',
'newcasino.popups.admin.detail.tab.french': 'Francês',
'newcasino.popups.admin.detail.tab.portuguese': 'Português',
'newcasino.popups.admin.detail.title.en': 'Título em Inglês',
'newcasino.popups.admin.detail.title.en.hint': 'Digite o título em inglês',
'newcasino.popups.admin.detail.title.fr': 'Título em Francês',
'newcasino.popups.admin.detail.title.fr.hint': 'Digite o título em francês',
'newcasino.popups.admin.detail.title.pt': 'Título em português',
'newcasino.popups.admin.detail.title.pt.hint': 'Digite o título em português',
'newcasino.popups.admin.list.add.button': 'Novo Pop-up',
'newcasino.popups.admin.list.col1': 'Data de Criação',
'newcasino.popups.admin.list.col2': 'Nome',
'newcasino.popups.admin.list.col3': 'Ativo',
'newcasino.popups.admin.list.no': 'Não',
'newcasino.popups.admin.list.title': 'Gerenciamento de Pop-ups',
'newcasino.popups.admin.list.yes': 'Sim',
'newcasino.profile.info.address.address.error': 'Endereço informado é muito curto',
'newcasino.profile.info.address.address.hint': 'Digite seu endereço aqui',
'newcasino.profile.info.address.address.label': 'Endereço',
'newcasino.profile.info.address.city.error': 'Nome da cidade é muito curto',
'newcasino.profile.info.address.city.hint': 'Digite o nome da cidade aqui',
'newcasino.profile.info.address.city.label': 'Cidade',
'newcasino.profile.info.address.country.hint': 'Escolha o seu país...',
'newcasino.profile.info.address.country.label': 'País',
'newcasino.profile.info.address.state.error': 'O nome do estado é muito curto',
'newcasino.profile.info.address.state.hint': 'Digite seu estado aqui',
'newcasino.profile.info.address.state.label': 'Estado',
'newcasino.profile.info.address.zip.error': 'CEP informado é muito curto',
'newcasino.profile.info.address.zip.hint': 'CEP',
'newcasino.profile.info.address.zip.label': 'CEP',
'newcasino.profile.info.button.change': 'Modificar',
'newcasino.profile.info.button.update': 'Atualizar',
'newcasino.profile.info.email.label': 'E-mail',
'newcasino.profile.info.fn.label': 'Nome',
'newcasino.profile.info.ln.label': 'Sobrenome',
'newcasino.profile.info.phone.label': 'Número de telefone',
'newcasino.profile.info.success': 'Suas informações foram modificadas.',
'newcasino.profile.password.button': 'Atualização',
'newcasino.profile.password.doc.legacy': 'Imagens Antigas',
'newcasino.profile.password.doc.newimages': 'Novas Imagens',
'newcasino.profile.password.doc.proof1.approve': 'Aprovar',
'newcasino.profile.password.doc.proof1.decline': 'Recusar',
'newcasino.profile.password.doc.proof1.desc': 'Passaporte ou Carteira de Motorista ou Carteira de Identidade Nacional.',
'newcasino.profile.password.doc.proof1.title': 'Comprovante de Identidade',
'newcasino.profile.password.doc.proof2.desc': 'Conta de serviços públicos ou extrato bancário',
'newcasino.profile.password.doc.proof2.title': 'Comprovante de Endereço',
'newcasino.profile.password.doc.select': 'Selecione a imagem',
'newcasino.profile.password.failure': 'Sua senha não pôde ser alterada, verifique sua senha atual.',
'newcasino.profile.password.input1.error': 'Senha informada é muito curta',
'newcasino.profile.password.input1.hint': 'Digite sua senha atual aqui',
'newcasino.profile.password.input1.label': 'Senha Atual',
'newcasino.profile.password.input2.error': 'Você precisa de pelo menos 8 caracteres com uma combinação de letras maiúsculas e minúsculas',
'newcasino.profile.password.input2.hint': 'Digite sua nova senha aqui',
'newcasino.profile.password.input2.label': 'Nova Senha',
'newcasino.profile.password.success': 'Sua senha foi alterada.',
'newcasino.profile.popup.phone.button.cancel': 'Cancelar',
'newcasino.profile.popup.phone.button.end': 'Validar',
'newcasino.profile.popup.phone.button.next': 'Enviar SMS',
'newcasino.profile.popup.phone.countrycode.hint': 'Código',
'newcasino.profile.popup.phone.countrycode.label': 'Selecione o código do país',
'newcasino.profile.popup.phone.number.hint': 'Número de telefone',
'newcasino.profile.popup.phone.number.label': 'Número de telefone',
'newcasino.profile.popup.phone.sms.failure.send': 'O SMS não pôde ser enviado, verifique o número ou entre em contato com o suporte',
'newcasino.profile.popup.phone.sms.failure.verify': 'O SMS não pôde ser verificado. Verifique o código ou entre em contato com o suporte',
'newcasino.profile.popup.phone.sms.success.send': 'O SMS foi enviado para o seu telefone',
'newcasino.profile.popup.phone.sms.success.verify': 'Seu número de telefone foi alterado',
'newcasino.profile.popup.phone.subtitle': 'Para alterar seu número de telefone, precisamos validar o novo número com um SMS.',
'newcasino.profile.popup.phone.title': 'Alterar seu número de telefone',
'newcasino.profile.tab.bank': 'Transações',
'newcasino.profile.tab.docs': 'Documentos de Identidade',
'newcasino.profile.tab.password': 'Alterar a Senha',
'newcasino.profile.tab.personal': 'Informações Pessoais',
'newcasino.profile.tab.pngpoints': 'Pontos PNG',
'newcasino.profile.tab.wallets': 'Carteiras',
'newcasino.profile.title': 'Meu Perfil',
'newcasino.promotions.admin.list.add.button': 'Novas Promoções',
'newcasino.promotions.admin.list.col0': 'Em andamento',
'newcasino.promotions.admin.list.col1': 'Nome da promoção',
'newcasino.promotions.admin.list.col2': 'Ativo',
'newcasino.promotions.admin.list.col3': 'Data de início',
'newcasino.promotions.admin.list.col4': 'Data final',
'newcasino.promotions.admin.list.title': 'Promoções',
'newcasino.promotions.admin.prom.add.success': 'A promoção foi adicionada',
'newcasino.promotions.admin.prom.mod.success': 'A promoção foi atualizada',
'newcasino.promotions.admin.prom.new.deposit.action': 'Ação sobre o valor do depósito',
'newcasino.promotions.admin.prom.new.deposit.chance': 'Chance de ganhar',
'newcasino.promotions.admin.prom.new.deposit.double': 'Dobro',
'newcasino.promotions.admin.prom.new.deposit.enddate': 'Data final',
'newcasino.promotions.admin.prom.new.deposit.endtime': 'Fim do tempo',
'newcasino.promotions.admin.prom.new.deposit.maxwin': 'Ganhos máximos por depósito',
'newcasino.promotions.admin.prom.new.deposit.select': 'Selecione um valor...',
'newcasino.promotions.admin.prom.new.deposit.startdate': 'Data de início',
'newcasino.promotions.admin.prom.new.deposit.starttime': 'Hora de início',
'newcasino.promotions.admin.prom.new.deposit.total': 'Valor total a distribuir',
'newcasino.promotions.admin.prom.new.deposit.triple': 'Triplo',
'newcasino.promotions.admin.prom.new.gift.audience.all': 'Todos',
'newcasino.promotions.admin.prom.new.gift.audience.select': 'Escolha os destinatários',
'newcasino.promotions.admin.prom.new.gift.audience.specific': 'Carregar lista de usuários',
'newcasino.promotions.admin.prom.new.gift.cash': 'Dinheiro',
'newcasino.promotions.admin.prom.new.gift.cash.amount': 'Valor em euros',
'newcasino.promotions.admin.prom.new.gift.cash.quantity': 'Quantidade',
'newcasino.promotions.admin.prom.new.gift.cron.date': 'Data de ativação',
'newcasino.promotions.admin.prom.new.gift.cron.time': 'Tempo de ativação',
'newcasino.promotions.admin.prom.new.gift.freespin': 'Rodadas grátis',
'newcasino.promotions.admin.prom.new.gift.png': 'Pontos PNG',
'newcasino.promotions.admin.prom.new.gift.select': 'Escolha um...',
'newcasino.promotions.admin.prom.new.gift.title': 'Tipo de presente',
'newcasino.promotions.admin.prom.new.gift.wager': 'Aposta',
'newcasino.promotions.admin.prom.new.gift.wager.amount': 'Valor em euros',
'newcasino.promotions.admin.prom.new.gift.wager.expiration.date': 'Data de Expiração',
'newcasino.promotions.admin.prom.new.gift.wager.expiration.time': 'Hora de Expiração',
'newcasino.promotions.admin.prom.new.gift.wager.maxwin': 'Vitória máxima',
'newcasino.promotions.admin.prom.new.gift.wager.xtime': 'Apostar X vezes',
'newcasino.promotions.admin.prom.new.name': 'Nome da promoção',
'newcasino.promotions.admin.prom.new.next': 'Próxima etapa',
'newcasino.promotions.admin.prom.new.title': 'Novas Promoções',
'newcasino.promotions.admin.prom.new.type': 'Tipo de promoção',
'newcasino.promotions.admin.prom.new.type.deposit': 'Bônus de Depósito',
'newcasino.promotions.admin.prom.new.type.instant': 'Presente Instantâneo',
'newcasino.promotions.admin.prom.new.type.select': 'Escolha um...',
'newcasino.promotions.admin.prom.new.upload': 'Selecione o arquivo CSV',
'newcasino.promotions.admin.prom.viewer.active': 'Ativo',
'newcasino.promotions.admin.prom.viewer.audience': 'Destinatários',
'newcasino.promotions.admin.prom.viewer.audience.all': 'Todos (Exceto banidos)',
'newcasino.promotions.admin.prom.viewer.audience.list': 'Lista CSV',
'newcasino.promotions.admin.prom.viewer.button.activate': 'Ativar',
'newcasino.promotions.admin.prom.viewer.button.deactivate': 'Desativar',
'newcasino.promotions.admin.prom.viewer.button.delete': 'Apagar',
'newcasino.promotions.admin.prom.viewer.button.download.original': 'Baixe a lista',
'newcasino.promotions.admin.prom.viewer.button.download.result': 'Baixar resultados',
'newcasino.promotions.admin.prom.viewer.cash.nbr': 'Dinheiro (Euro)',
'newcasino.promotions.admin.prom.viewer.cronned': 'Executado (Robô)',
'newcasino.promotions.admin.prom.viewer.deposit.chance': 'Chance de ganhar (%)',
'newcasino.promotions.admin.prom.viewer.deposit.end': 'Fim (horário de Londres)',
'newcasino.promotions.admin.prom.viewer.deposit.max': 'Ganho máximo por depósito',
'newcasino.promotions.admin.prom.viewer.deposit.start': 'Início (horário de Londres)',
'newcasino.promotions.admin.prom.viewer.deposit.stats.wineuro': 'Dinheiro dado',
'newcasino.promotions.admin.prom.viewer.deposit.total': 'Dinheiro total para distribuir',
'newcasino.promotions.admin.prom.viewer.freespins.nbr': 'Rodadas Grátis',
'newcasino.promotions.admin.prom.viewer.gift': 'Tipo de presente',
'newcasino.promotions.admin.prom.viewer.png.nbr': 'Pontos PNG',
'newcasino.promotions.admin.prom.viewer.promo.deposit': 'Bônus ao depositar',
'newcasino.promotions.admin.prom.viewer.promo.gift': 'Presente instantâneo',
'newcasino.promotions.admin.prom.viewer.type': 'Tipo de promoção',
'newcasino.promotions.admin.prom.viewer.wager.expDate': 'Data de validade',
'newcasino.promotions.admin.prom.viewer.wager.maxwin': 'Ganho máximo (Euro)',
'newcasino.promotions.admin.prom.viewer.wager.nbr': 'Valor do bônus (Euro)',
'newcasino.promotions.admin.prom.viewer.wager.stats.inprogress': 'Em andamento',
'newcasino.promotions.admin.prom.viewer.wager.stats.looser': 'Perdedores',
'newcasino.promotions.admin.prom.viewer.wager.stats.nbr': 'Bônus dados',
'newcasino.promotions.admin.prom.viewer.wager.stats.title': 'Estatisticas',
'newcasino.promotions.admin.prom.viewer.wager.stats.wineuro': 'Ganhos totais em euros',
'newcasino.promotions.admin.prom.viewer.wager.stats.winners': 'Vencedores',
'newcasino.promotions.admin.prom.viewer.wager.x': 'Multiplicador',
'newcasino.referafriend.button.claim': 'Reivindicar!',
'newcasino.referafriend.claim': 'REIVINDICAR MINHAS APOSTAS',
'newcasino.referafriend.copied': 'Copiado!',
'newcasino.referafriend.copy': 'Copiar Link',
'newcasino.referafriend.header.amount': 'Quantia',
'newcasino.referafriend.header.currency': 'Moeda',
'newcasino.referafriend.header.name': 'Nome do Amigo',
'newcasino.referafriend.info.part1': 'Ganhe um Bônus de Depósito Correspondente a cada convite bem-sucedido.Quando seu amigo depositar um mínimo de {minimalDeposit} e jogar {wagerRequired} no primeiro depósito, você receberá a mesma quantia com um requisito de aposta de {wagerRequiredRecipient}. Indique quantos amigos quiser - Sem limites. Divirta-se compartilhando e aproveite as recompensas hoje!',
'newcasino.referafriend.info.part1-minimalDeposit': '$10',
'newcasino.referafriend.info.part1-wagerRequired': '1x',
'newcasino.referafriend.info.part1-wagerRequiredRecipient': '35x',
'newcasino.referafriend.info.part2': 'Seu amigo terá a oportunidade de escolher sua oferta de boas-vindas.',
'newcasino.referafriend.info.part3': 'Para reivindicar o Bônus de Depósito Correspondente, basta clicar em "Reivindicar minha Aposta" aqui na página Indique um Amigo.',
'newcasino.referafriend.invite': 'Convide seus {friends}',
'newcasino.referafriend.invite.part1': 'amigos',
'newcasino.referafriend.money.claimed': 'Apostas que você já reivindicou',
'newcasino.referafriend.money.to.claim': 'Apostas Disponíveis',
'newcasino.referafriend.nbr.player.deposit': 'Amigos que depositaram dinheiro',
'newcasino.referafriend.nbr.player.use.link': 'Amigos que se registraram com seu link',
'newcasino.referafriend.nbr.player.wager': 'Amigos que lhe dão direito a uma aposta',
'newcasino.referafriend.popup.close': 'Cancelar',
'newcasino.referafriend.popup.text': 'Selecione a(s) aposta(s) que deseja usar. Se os valores estiverem em moeda estrangeira, eles serão convertidos automaticamente',
'newcasino.referafriend.popup.title': 'Reivindicar minhas Apostas',
'newcasino.referafriend.popup.wait': 'Por favor, aguarde...',
'newcasino.referafriend.title': 'Indique um amigo',
'newcasino.referafriend.wager.success': 'Incrível! Você acabou de ganhar {money} com um valor de aposta de {wager}.',
'newcasino.reset.password.failure': 'Sua senha não pode ser redefinida, este link pode ter expirado.',
'newcasino.reset.password.password.label': 'Nova Senha',
'newcasino.reset.password.success': 'Sua senha foi alterada',
'newcasino.reset.password.title': 'Redefinir sua senha',
'newcasino.rg.activity.check.page.120': '120 minutos',
'newcasino.rg.activity.check.page.30': '30 minutos',
'newcasino.rg.activity.check.page.60': '60 minutos',
'newcasino.rg.activity.check.page.checkbox': 'Ao clicar aqui, entendo que estou configurando uma verificação de atividade automatizada',
'newcasino.rg.activity.check.page.subtitle': 'QUEREMOS QUE VOCÊ APROVEITE SUA EXPERIÊNCIA DE JOGO DE FORMA RESPONSÁVEL EM NOSSO CASSINO, POR ISSO OFERECEMOS UM RECURSO DE VERIFICAÇÃO DE ATIVIDADE. ELE AJUDA A CONTROLAR O TEMPO QUE VOCÊ PASSA JOGANDO, ENVIANDO NOTIFICAÇÕES AJUSTÁVEIS QUE FUNCIONAM COMO UM DESPERTADOR, COMEÇANDO ASSIM QUE VOCÊ FIZER O LOGIN.',
'newcasino.rg.activity.check.page.title': 'Verificação de Atividade',
'newcasino.rg.activity.check.success.1': 'A partir de agora você receberá um alerta após',
'newcasino.rg.activity.check.success.2': 'minutos de jogo',
'newcasino.rg.deposit.limit.page.button': 'Definir/Atualizar',
'newcasino.rg.deposit.limit.page.daily': 'Seu limite diário de depósito atual é:',
'newcasino.rg.deposit.limit.page.monthly': 'Seu limite mensal de depósito atual é:',
'newcasino.rg.deposit.limit.page.subtitle': '* Os valores só podem ser alterados uma vez a cada 48 horas.',
'newcasino.rg.deposit.limit.page.title': 'Limite de Depósito',
'newcasino.rg.deposit.limit.page.weekly': 'Seu limite de depósito semanal atual é:',
'newcasino.rg.lost.limit.page.button': 'Definir/Atualizar',
'newcasino.rg.lost.limit.page.daily': 'Seu limite diário de perdas atual é:',
'newcasino.rg.lost.limit.page.monthly': 'Seu limite mensal de perdas atual é:',
'newcasino.rg.lost.limit.page.subtitle': '* Os valores só podem ser alterados uma vez a cada 48 horas',
'newcasino.rg.lost.limit.page.title': 'Limite de Perda',
'newcasino.rg.lost.limit.page.weekly': 'Seu limite de perda semanal atual é:',
'newcasino.rg.self.exclusion.page.1': '1 ano',
'newcasino.rg.self.exclusion.page.3': '3 anos',
'newcasino.rg.self.exclusion.page.5': '5 anos',
'newcasino.rg.self.exclusion.page.6': '6 meses',
'newcasino.rg.self.exclusion.page.checkbox': 'Ao clicar aqui, entendo que esta ação bloqueará minha conta',
'newcasino.rg.self.exclusion.page.subtitle': 'NO NUMBERS GAME, ACREDITAMOS NO JOGO RESPONSÁVEL. SE VOCÊ ACHAR QUE ESTÁ JOGANDO MAIS DO QUE DEVERIA, RECOMENDAMOS QUE FAÇA UMA PAUSA E CONSIDERE A AUTOEXCLUSÃO. LEMBRE-SE, JOGAR DE FORMA RESPONSÁVEL É FUNDAMENTAL PARA DESFRUTAR DOS CASSINOS ONLINE COM SEGURANÇA.',
'newcasino.rg.self.exclusion.page.title': 'Autoexclusão',
'newcasino.rg.self.exclusion.success': 'Sua conta está bloqueada até:',
'newcasino.rg.take.break.page.24': '24 horas',
'newcasino.rg.take.break.page.30': '30 dias',
'newcasino.rg.take.break.page.7': '7 dias',
'newcasino.rg.take.break.page.checkbox': 'Ao clicar aqui, entendo que esta ação bloqueará minha conta',
'newcasino.rg.take.break.page.subtitle': 'PRIORIZAMOS O JOGO RESPONSÁVEL PARA PROPORCIONAR UMA EXPERIÊNCIA SEGURA E AGRADÁVEL EM NOSSO CASSINO ONLINE. SE VOCÊ PRECISAR DE UMA PEQUENA PAUSA NO JOGO, NOSSO RECURSO "FAÇA UMA PAUSA" PERMITE SUSPENDER TEMPORARIAMENTE SUA CONTA. SE VOCÊ PRECISAR DE UMA PAUSA MAIS LONGA, NÃO HESITE EM VERIFICAR NOSSO RECURSO DE AUTOEXCLUSÃO.',
'newcasino.rg.take.break.page.title': 'Faça uma pausa',
'newcasino.rg.take.break.success': 'Sua conta está bloqueada até:',
'newcasino.search.noresult': 'Nenhum resultado encontrado.',
'newcasino.search.result.title1': 'Resultados da Pesquisa -',
'newcasino.search.result.title2': 'item(s)',
'newcasino.session.fourOone': 'Você não tem acesso a esta página. Por favor, faça login novamente.',
'newcasino.session.fourOone.title': 'Erro',
'newcasino.session.inactive.msg': 'Sua sessão expirou. Por favor, faça login novamente.',
'newcasino.session.inactive.title': 'Sessão expirada',
'newcasino.session.inactivity': 'Sua sessão expirou devido à inatividade. Sua segurança é nossa preocupação, por favor faça login novamente.',
'newcasino.session.inactivity.title': 'Sessão Expirada',
'newcasino.session.maintenance': 'O site está atualmente em manutenção. Não vai demorar muito, volte em alguns instantes',
'newcasino.session.maintenance.title': 'Atualização em andamento',
'newcasino.store.elements.cash': 'Dinheiro',
'newcasino.store.elements.freespin': 'Rodada Grátis',
'newcasino.store.elements.wager': 'Aposta',
'newcasino.streamer.claim': 'Faça uma Aposta',
'newcasino.streamer.copied': 'Copiado',
'newcasino.streamer.copy': 'Copiar Link',
'newcasino.streamer.deal': 'Sua oferta: {valor} - {xVezes}x, {porSemana} por semana',
'newcasino.streamer.giveaway': 'Presente para seus seguidores: Disponível: {availableGift}',
'newcasino.streamer.info.part1': 'Olá, Streamer! Aqui você encontrará todas as ferramentas necessárias para proporcionar a melhor experiência aos seus seguidores.',
'newcasino.streamer.invite': 'Convide seus {viewers}',
'newcasino.streamer.invite.part1': 'seguidores',
'newcasino.streamer.money.to.claim': 'Ofertas Disponíveis',
'newcasino.streamer.pending': 'Pendente de aprovação',
'newcasino.streamer.title': 'Streamer',
'newcasino.streamer.vault': 'Seu Cofre',
'newcasino.streamer.vault.amount': 'Seu cofre contém',
'newcasino.success.delete.contest': 'Concurso excluído',
'newcasino.success.delete.popup': 'Pop-up excluído',
'newcasino.success.save.contest': 'Concurso salvo',
'newcasino.success.save.popup': 'Pop-up salvo',
'newcasino.success.title': 'Sucesso',
'newcasino.user.address.address.fn.error': 'O nome é muito curto',
'newcasino.user.address.address.fn.hint': 'Digite o Nome',
'newcasino.user.address.address.fn.label': 'Nome',
'newcasino.user.address.address.ln.error': 'O sobrenome é muito curto',
'newcasino.user.address.address.ln.hint': 'Digite Sobrenome',
'newcasino.user.address.address.ln.label': 'Sobrenome',
'newcasino.user.address.kyc.approved': 'Aprovado',
'newcasino.user.address.kyc.nophoto': 'Sem Foto',
'newcasino.user.address.kyc.pending': 'Pendente',
'newcasino.user.address.kyc.rejected': 'Rejeitado',
'newcasino.user.address.kyc.unknown': 'Desconhecido',
'newcasino.user.address.phonestatus.empty': 'Sem Telefone',
'newcasino.user.address.phonestatus.failed3x': 'Falhou 3x',
'newcasino.user.address.phonestatus.unknown': 'Desconhecido',
'newcasino.user.address.phonestatus.verif': 'Verificado',
'newcasino.user.address.ustatus.active': 'Ativo',
'newcasino.user.address.ustatus.inactive': 'Inativo',
'newcasino.user.address.ustatus.unknown': 'Desconhecido',
'newcasino.user.address.utype.admin': 'Administrador',
'newcasino.user.address.utype.partner': 'Parceiro',
'newcasino.user.address.utype.player': 'Jogador',
'newcasino.user.address.utype.streamer': 'Streamer',
'newcasino.user.address.utype.unknown': 'Desconhecido',
'newcasino.usermenu.deposit': 'Depósito / Saque',
'newcasino.usermenu.logout': 'Sair',
'newcasino.usermenu.profile': 'Editar Perfil',
'newcasino.wallet.tx.type.0': 'Criação de carteira',
'newcasino.wallet.tx.type.100': 'Depósito - Manual',
'newcasino.wallet.tx.type.101': 'Depósito - Banco',
'newcasino.wallet.tx.type.102': 'Depósito - Reversão de saque',
'newcasino.wallet.tx.type.103': 'Depósito - Pontos PNG trocados',
'newcasino.wallet.tx.type.104': 'Depósito - Promo bônus',
'newcasino.wallet.tx.type.110': 'Depósito - Vitória',
'newcasino.wallet.tx.type.115': 'Depósito - Sorteio',
'newcasino.wallet.tx.type.120': 'Depósito - Aposta e Vitória',
'newcasino.wallet.tx.type.125': 'Depósito - Criação de Apostas',
'newcasino.wallet.tx.type.130': 'Depósito - Reversão de Aposta',
'newcasino.wallet.tx.type.140': 'Depósito - Migração de Sistema',
'newcasino.wallet.tx.type.150': 'Depósito - Streamer Pago',
'newcasino.wallet.tx.type.200': 'Retirar - Manual',
'newcasino.wallet.tx.type.201': 'Retirar - Banco',
'newcasino.wallet.tx.type.202': 'Retirar - Para Bônus',
'newcasino.wallet.tx.type.210': 'Retirar - Aposta',
'newcasino.wallet.tx.type.230': 'Retirar - Vitória Anulada',
'newcasino.wallet.tx.type.300': 'Carteira Fechada - Dinheiro Transferido',
}

export default {
locale: 'pt-BR',
localeAntd,
messages
}
